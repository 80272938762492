import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // Tracks animation phase
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = sessionStorage.getItem("isAuthenticated");
    setIsAuthenticated(authStatus === "true");
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://fornite-backend.onrender.com/api/change-password",
        { currentPassword, newPassword }
      );

      if (response.data.success) {
        toast.success("Password updated successfully!", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        closeModal();
      } else {
        toast.error(response.data.message || "Failed to update password.", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
    } catch (error) {
      console.error("Error during password change:", error);
      toast.error("An error occurred while changing the password.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const openModal = () => {
    if (isAuthenticated) {
      setIsVisible(true);
      setIsAnimating(true);
    } else {
      toast.error("You do not have permission to edit the map.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const closeModal = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsVisible(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }, 300);
  };

  return (
    <>
      <button
        className="absolute top-20 right-4 z-[100000] bg-[#ffe262] text-black px-4 py-2 rounded-md shadow-lg hover:bg-[#ffd200] transition-all"
        onClick={openModal}
      >
        <FontAwesomeIcon icon={faLock} />
      </button>
      {isVisible && (
        <div
          className={`fixed left-1/2 top-1/2 z-[99999999] xxl:w-1/3 max-lg:w-1/2 fade_opacity max-md:w-4/5 py-8 px-10 bg-[#1b1b1b] border-2 rounded-lg shadow-lg transform transition-all duration-300 ${
            isAnimating ? "opacity-100 scale-100" : "opacity-0 scale-90"
          } -translate-x-1/2 -translate-y-1/2`}
        >
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white text-2xl font-bold hover:text-gray-300 transition-all"
          >
            &times;
          </button>
          <h2 className="text-center text-2xl font-semibold text-white mb-6">
            Change Password
          </h2>
          <form onSubmit={handlePasswordChange}>
            <div className="mb-5">
              <label className="text-sm mb-2 text-gray-200 cursor-pointer" htmlFor="currentPassword">
                Current Password
              </label>
              <input
                type="text"
                value={currentPassword}
                id="currentPassword"
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 w-full focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                required
                autoFocus
              />
            </div>
            <div className="mb-5">
              <label className="text-sm mb-2 text-gray-200 cursor-pointer" htmlFor="newPassword">
                New Password
              </label>
              <input
                type="text"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 w-full focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                required
              />
            </div>
            <div className="mb-5">
              <label className="text-sm mb-2 text-gray-200 cursor-pointer" htmlFor="confirmPassword">
                Confirm New Password
              </label>
              <input
                type="text"
                value={confirmPassword}
                id="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 w-full focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-all ease-in-out duration-200"
            >
              Update Password
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default ChangePassword;
