import React, { useState } from 'react';
import MapView from './MapView';
import Modal from './Modal';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const openVideoModal = (url) => {
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVideoUrl('');
  };

  return (
    <div>
      <MapView openVideoModal={openVideoModal} />
      <Modal isModalOpen={isModalOpen} videoUrl={videoUrl} closeModal={closeModal} />
    </div>
  );
};

export default Home;
