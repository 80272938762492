import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Usemode = () => {
  const [flag, setFlag] = useState(false); // Controls modal visibility
  const [password, setPassword] = useState("");
  const modalRef = useRef(null);

  useEffect(() => {
    const authStatus = sessionStorage.getItem("isAuthenticated");
    if (authStatus === "true") {
      setFlag(false); // Ensure modal is hidden if already authenticated
    }
  }, []);

  const handleSubmit = async () => {
    if (!password) {
      toast.error("Please enter a password", {
        position: "top-center",
        autoClose: 1000,
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://fornite-backend.onrender.com/api/verify",
        { password }
      );

      if (response.data.success) {
        sessionStorage.setItem("isAuthenticated", "true");
        toast.success("Access granted", {
          position: "top-center",
          autoClose: 1000,
        });
        setFlag(false); // Close modal after successful authentication
      } else {
        toast.error("Invalid password", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error during authentication:", error.response || error);
      toast.error("Error occurred while verifying password", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setFlag(false); // Close modal on outside click
    }
  };

  const HandleFlag = () => {
    const authStatus = sessionStorage.getItem("isAuthenticated");
    if (authStatus === "true") {
      toast.info("You are already authenticated", {
        position: "top-center",
        autoClose: 1000,
      });
    } else {
      setFlag(true); // Show modal only if not authenticated
    }
  };

  return (
    <>
      <div className="text-center text-white pb-5">
        <div className="text-center text-white" onClick={HandleFlag}>
          Created by @Zyfe
        </div>
      </div>
      {flag && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[9999999] flex items-center justify-center"
          onClick={handleOutsideClick}
        >
          <div
            ref={modalRef}
            className="bg-white p-5 rounded shadow-lg"
            onClick={(e) => e.stopPropagation()} // Prevent propagation
          >
            <div className="text-center">Please confirm password</div>
            <div className="flex gap-5 mt-3">
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full focus:outline-1 focus:outline-[#a1a1a1]"
                placeholder="Confirm password..."
              />
              <button
                onClick={handleSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Usemode;
