const Follows = () => {
  return (
    <div className="mt-8">
      <h3 className="text-lg font-bold mb-4 text-white text-center">
        Follow Us
      </h3>
      <div className="flex justify-around gap-6">
        <a
          href="https://www.youtube.com/@izyfe"
          target="_blank"
          rel="noopener noreferrer"
          className="text-red-600 text-2xl hover:opacity-80 transition"
        >
          <img src="/youtube.png" alt="" srcSet="" className="w-16" />
        </a>
        <a
          href="https://discord.com/invite/m7cTHrEEK8"
          target="_blank"
          rel="noopener noreferrer"
          className="text-pink-600 text-2xl hover:opacity-80 transition"
        >
          <img src="/discord.png" alt="" srcSet="" className="w-16" />
        </a>
        <a
          href="https://www.tiktok.com/@zyfetok"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 text-2xl hover:opacity-80 transition"
        >
          <img src="/tiktok.png" alt="" srcSet="" className="w-16" />
        </a>
      </div>
    </div>
  );
};

export default Follows;
