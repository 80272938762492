import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

const Modal = ({ isModalOpen, videoUrl, closeModal }) => {
  const [url, setUrl] = useState('');

  const extractYoutubeId = (url) => {
    if (!url) return null; // Handle null or undefined URLs
    const trimmedUrl = url.trim();
    console.log('Trimmed URL:', trimmedUrl);
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:shorts\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = trimmedUrl.match(regex);
    console.log('Regex match:', match);
    return match ? match[1] : null;
  };

  useEffect(() => {
    if (videoUrl) {
      console.log('Input videoUrl:', videoUrl);
      const id = extractYoutubeId(videoUrl.trim());
      console.log('Extracted ID:', id);
      setUrl(id ? `https://www.youtube.com/embed/${id}` : null);
    } else {
      console.log('No videoUrl provided.');
      setUrl(null);
    }
  }, [videoUrl]);

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="YouTube Video"
      ariaHideApp={false}
      className="fixed inset-0 z-[99999999] flex justify-center items-center w-4/5 h-4/5 m-auto bg-[#000000c9]"
      overlayClassName="bg-black/75 z-[99999999]"
    >
      {url ? (
        <iframe
          className="w-full h-full"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube Video"
        ></iframe>
      ) : (
        <div className="text-white flex items-center justify-center w-full h-full">
          <p>Invalid YouTube URL</p>
        </div>
      )}
      <button
        onClick={closeModal}
        className="absolute top-2 right-2 text-white bg-black/50 border-0 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer text-xl font-bold transition-all duration-300 ease-in-out"
      >
        &times;
      </button>
    </ReactModal>
  );
};

export default Modal;

