import React, { useState, useEffect } from "react";

const Loading = ({ text }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false); // Hide after 3 seconds
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`fixed left-1/2 top-16 z-[9999999999999] transform -translate-x-1/2 ${
        isVisible ? "animate-slideIn" : "animate-slideOut"
      } flex items-center justify-center flex-col`}
    >
      <div className="w-10 h-10 border-4 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
      <div className="text-white">{text}</div>
    </div>
  );
};

export default Loading;

