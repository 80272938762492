export const Map1 = [
  {
    position: { lat: 82.58610635020881, lng: 156.0436617411916 },
    icon: "THE YACHT",
  },
  {
    position: { lat: 73.82482034613932, lng: 132.8405367411916 },
    icon: "STEAMY STACKS",
  },
  {
    position: { lat: 17.978733095556183, lng: 158.1530367411916 },
    icon: "DIRTY DOCKS",
  },
  {
    position: { lat: -23.241346102386135, lng: 153.93428674119158 },
    icon: "SPAGHETTI GROTTO",
  },
  {
    position: { lat: -44.59046718130884, lng: 138.4655367411916 },
    icon: "RETAIL ROW",
  },
  {
    position: { lat: -54.1624339680678, lng: 75.88741174119157 },
    icon: "LAZY LAKE",
  },
  {
    position: { lat: -76.51681887717322, lng: 33.69991174119157 },
    icon: "MISTY MEADOWS",
  },
  {
    position: { lat: 78.06198918665976, lng: 46.35616174119157 },
    icon: "CRAGGY CLIFFS",
  },
  {
    position: { lat: 47.989921667414194, lng: 56.903036741191585 },
    icon: "FRENZY FARM",
  },
  {
    position: { lat: 65.07213008560697, lng: -19.034463258808433 },
    icon: "PLEASANT PARK",
  },
  {
    position: { lat: 21.94304553343818, lng: 21.94304553343818 },
    icon: "THE DOGGPOUND",
  },
  {
    position: { lat: 31.353636941500987, lng: -32.34375000000001 },
    icon: "SALTY SPRINGS",
  },
  {
    position: { lat: -34.88593094075316, lng: -55.59696325880844 },
    icon: "WEEPING WOODS",
  },
  {
    position: { lat: -65.94647177615738, lng: -66.14383825880843 },
    icon: "SLURPY SWAMP",
  },
  {
    position: { lat: -72.81607371878991, lng: -113.95633825880843 },
    icon: "THE RIG",
  },
  {
    position: { lat: -17.978733095556155, lng: -106.92508825880846 },
    icon: "HOLLY HEDGES",
  },
  {
    position: { lat: 38.272688535980976, lng: -98.48758825880843 },
    icon: "SWEATY SANDS",
  },
  {
    position: { lat: 81.20141954209073, lng: -113.25321325880843 },
    icon: "ICE ISLE",
  },
];

export const Map2 = [
  {
    position: { lat: 53.330872983017066, lng: -12.656250000000002 },
    icon: "LONE LODGE",
  },
  {
    position: { lat: 15.961329081596647, lng: -71.71875000000001 },
    icon: "PLEASANT PARK",
  },
  {
    position: { lat: 33.7243396617476, lng: 71.71875000000001 },
    icon: "SNOBBY SHOALS",
  },
  {
    position: { lat: -34.30714385628803, lng: -57.65625000000001 },
    icon: "LIL' LOOT LAKE",
  },
  {
    position: { lat: -27.68352808378776, lng: 20.390625000000004 },
    icon: "TILTED TOWERS",
  },
  {
    position: { lat: -18.646245142670608, lng: 80.85937500000001 },
    icon: "SANDY SHEETS",
  },
  {
    position: { lat: -68.13885164925573, lng: -26.718750000000004 },
    icon: "DUSTY DOCKS",
  },
  {
    position: { lat: -65.94647177615738, lng: 38.67187500000001 },
    icon: "LAZY LAPS",
  },
  {
    position: { lat: -58.44773280389084, lng: 104.765625 },
    icon: "RETAIL ROW",
  },
];


export const Map3 = [
	{
	  position: { lat: 54.20701015422204, lng: -17.312334767729105 },
	  icon: "SNOBBY SANDS",
	},
	{
	  position: { lat: 44.64469003772172, lng: 57.57047773227088 },
	  icon: "FOSSIL FIELDS",
	},
	{
	  position: { lat: 15.357653861728156, lng: -32.429522267729105 },
	  icon: "LIZARD LINKS",
	},
	{
	  position: { lat: 13.314085362607145, lng: 107.49235273227089 },
	  icon: "ADOBE ABODES",
	},
	{
	  position: { lat: -18.906975527560988, lng: -93.24983476772911 },
	  icon: "TWISTED TRAILERS",
	},
	{
	  position: { lat: -27.927117550261894, lng: 17.492352732270877 },
	  icon: "PARADISE PALMS",
	},
	{
	  position: { lat: -58.59154114786636, lng: -66.53108476772911 },
	  icon: "SUNBURNT SHAFTS",
	},
	{
	  position: { lat: -57.47488834394595, lng: 121.9064152322709 },
	  icon: "GUACO TOWN",
	},
	{
	  position: { lat: -76.41672457343525, lng: 76.90641523227089 },
	  icon: "SHADY SPRINGS",
	},
]


export const Map4 = [
	{
	  position: { lat: 70.8446726342528, lng: -115.67273971532038 },
	  icon: "WHIFFY WHARF",
	},
	{
	  position: { lat: 72.18180355624855, lng: -37.27214740572522 },
	  icon: "FLOODED FROGS",
	},
	{
	  position: { lat: 65.80277639340238, lng: 5.2680842958936855 },
	  icon: "MAGIC MOSSES",
	},
	{
	  position: { lat: 58.99531118795094, lng: 52.37875411421541 },
	  icon: "PUMPED POWER",
	},
	{
	  position: { lat: 60.06484046010452, lng: 97.7315631184207 },
	  icon: "DEMON'S DOJO",
	},
	{
	  position: { lat: 36.03133177633189, lng: -110.04758511014762 },
	  icon: "TWINKLE TERRACE",
	},
	{
	  position: { lat: 47.040182144806664, lng: -48.52245661607071 },
	  icon: "LOST LAKE",
	},
	{
	  position: { lat: 41.244772343082076, lng: 2.103934830484011 },
	  icon: "BRUTAL BOXCARS",
	},
	{
	  position: { lat: -7.01366792756663, lng: -109.69601294732433 },
	  icon: "NIGHTSHIFT FOREST",
	},
	{
	  position: { lat: -19.642587534013032, lng: -16.88096196197399 },
	  icon: "FOXY FLOODGATE",
	},
	{
	  position: { lat: -17.644022027872726, lng: 56.246047905271695 },
	  icon: "SEAPORT CITY",
	},
	{
	  position: { lat: -5.266007882805498, lng: 95.97370230430421 },
	  icon: "SHINING SPAN",
	},
	{
	  position: { lat: -30.44867367928756, lng: -72.07779152523158 },
	  icon: "WARRIOR'S WATCH",
	},
	{
	  position: { lat: -45.08903556483102, lng: -118.48531701790674 },
	  icon: "BURD",
	},
	{
	  position: { lat: -65.07213008560697, lng: -78.40609045605093 },
	  icon: "CANYON CROSSING",
	},
	{
	  position: { lat: -67.06743335108298, lng: 8.080661598480058 },
	  icon: "MASKED MEADOWS",
	},
	{
	  position: { lat: -73.32785809840696, lng: -117.78217269226013 },
	  icon: "SHOGUN'S SOLITUDE",
	},
	{
	  position: { lat: -72.50172235139388, lng: 65.73849630150072 },
	  icon: "HOPEFUL HEIGHTS",
	},
]