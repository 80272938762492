import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  Marker,
  Popup,
  ZoomControl,
} from "react-leaflet";
import L from "leaflet";
import redMarker from "./red.png";
import blueMarker from "./blue.png";
import { Map2, Map3, Map4 } from "../../Source/defaultLocaton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faEdit,
  faEye,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "./Loading";
import ChangePassword from "./ChangePassword";
import Usemode from "./Usemode";
import Follows from "./Follows";

const MapView = ({ openVideoModal }) => {
  const [markersLayer2, setMarkersLayer2] = useState([]);
  const [markersLayer3, setMarkersLayer3] = useState([]);
  const [markersLayer4, setMarkersLayer4] = useState([]);
  const [location2, SetLocation2] = useState([]);
  const [location3, SetLocation3] = useState([]);
  const [location4, SetLocation4] = useState([]);
  const [position, SetPosition] = useState([0, 0]);
  const [isEditing, setIsEditing] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [currentLatLng, setCurrentLatLng] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [current, SetCurrent] = useState("/map/66/{z}/{x}/{y}.jpg");
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [markerLoding, setMarkerLoading] = useState(false);
  const [markerType, setMarkerType] = useState("red");
  const [locationShow, SetLocationShow] = useState(true);
  const [markerShow, SetmarkerShow] = useState(true);
  const [redMarkerShow, setRedMarkerShow] = useState(true);
  const [blueMarkerShow, setBlueMarkerShow] = useState(true);
  const [down, setDown] = useState(false);
  const mapRef = useRef();

  const customRedIcon = new L.Icon({
    iconUrl: redMarker,
    iconSize: [32, 32],
  });
  const customBlueIcon = new L.Icon({
    iconUrl: blueMarker,
    iconSize: [32, 32],
  });

  const handleEditControl = () => {
    if (sessionStorage.getItem("isAuthenticated")) {
      setIsEditing(!isEditing);
    } else {
      toast.error("You do not have permission to edit the map", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const resetMap = () => {
    SetPosition([0, 0]);
    mapRef.current?.flyTo([0, 0], 1);
  };

  useEffect(() => {
    SetLocation2(Map2);
    SetLocation3(Map3);
    SetLocation4(Map4);
  }, []);

  useEffect(() => {
    const fetchMarkers = async () => {
      try {
        setMarkerLoading(true);
        const encodedCurrent = encodeURIComponent(current);

        const response = await axios.get(
          `https://fornite-backend.onrender.com/api/markers/${encodedCurrent}`
        );
        setMarkerLoading(false);
        const markers = response.data;
        switch (current) {
          case "/map/44/{z}/{x}/{y}.jpg":
            setMarkersLayer2(markers);
            break;
          case "/map/55/{z}/{x}/{y}.jpg":
            setMarkersLayer3(markers);
            break;
          case "/map/66/{z}/{x}/{y}.jpg":
            setMarkersLayer4(markers);
            break;
          default:
            setMarkersLayer2([]);
            setMarkersLayer3([]);
            setMarkersLayer4([]);
        }
      } catch (error) {
        console.error("Error fetching markers", error);
      }
    };

    if (current) {
      fetchMarkers();
    }
  }, [current]);

  const HandleSetmarkerShow = () => {
    SetmarkerShow(!markerShow);
    setBlueMarkerShow(!markerShow);
    setRedMarkerShow(!markerShow);
  };

  const HandleElementShow = (color) => {
    color === "red" && setRedMarkerShow((prev) => !prev);
    color === "blue" && setBlueMarkerShow((prev) => !prev);
  };

  useEffect(() => {
    SetmarkerShow(redMarkerShow || blueMarkerShow);
  }, [redMarkerShow, blueMarkerShow]);

  const SideBar = () => {
    const [flag, setFalg] = useState(false);
    const toggle = () => {
      let toggle = document.getElementById("toggle");
      toggle.classList.toggle("toggle");
      setFalg(!flag);
    };
    return (
      <>
        <div className="fixed top-[30px] left-[30px] p-3 max-[768px]:right-[15px] max-[768px]:top-[15px] z-[9999999]">
          <div className="z-10 w-full cursor-pointer bg-red" id="toggle" onClick={toggle}>
            <div className="bg-[#ffe262] w-8 h-1 my-1 right-rotate transition duration-300"></div>
            <div className="bg-[#ffe262] w-8 h-1 my-1 middle_hidden transition duration-300"></div>
            <div className="bg-[#ffe262] w-8 h-1 my-1 left-rotate transition duration-300"></div>
          </div>
        </div>
        <div
          className={`bg-[#1b1b1b] w-[400px] max-sm:w-[100vw] border-r pt-20 md:px-3 px-7 border-[#838383] relative top-0 left-0 bottom-0 z-[99999] transition-transform duration-700 ${!flag ? "block" : "hidden"}`}
        >
          <div className="flex items-center justify-center">
            <img src="/logo.png" alt="logo" className="w-48" />
          </div>
          <div className="px-4 py-8 text-white">
            <div className="flex items-center justify-around gap-5 max-xl:flex-col">
              <img
                src="/4.jpg"
                alt=""
                className={`w-24 h-16 cursor-pointer transition duration-150 rounded-md ${current === "/map/66/{z}/{x}/{y}.jpg" &&
                  "border-4 border-white"
                  }`}
                onClick={() => SetCurrent("/map/66/{z}/{x}/{y}.jpg")}
              />
              <img
                src="/2.jpg"
                alt=""
                className={`w-24 h-16 cursor-pointer transition duration-150 rounded-md ${current === "/map/44/{z}/{x}/{y}.jpg" &&
                  "border-4 border-white"
                  }`}
                onClick={() => SetCurrent("/map/44/{z}/{x}/{y}.jpg")}
              />
              <img
                src="/3.jpg"
                alt=""
                className={`w-24 h-16 cursor-pointer transition duration-150 rounded-md ${current === "/map/55/{z}/{x}/{y}.jpg" &&
                  "border-4 border-white"
                  }`}
                onClick={() => SetCurrent("/map/55/{z}/{x}/{y}.jpg")}
              />
            </div>
          </div>
          <div className="border border-l-0 border-r-0 py-8 border-[#ffe262]">
            <div className="flex flex-col gap-5 pl-3">
              <div className="flex items-center">
                <label
                  className={`relative inline-block h-8 w-14 cursor-pointer rounded-full transition [-webkit-tap-highlight-color:_transparent] ${!locationShow ? "bg-[#424242]" : "bg-[#87ff62]"
                    }`}
                >
                  <input
                    className="sr-only peer"
                    id="AcceptConditions"
                    type="checkbox"
                    checked={locationShow}
                    onChange={() => SetLocationShow(!locationShow)}
                  />
                  <span className="absolute inset-y-0 px-2 start-0 m-1 size-6 rounded-full bg-gray-300 ring-[6px] ring-inset ring-white transition-all peer-checked:start-8 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"></span>
                </label>
                <div className="pl-5 text-xl text-white max-md:text-sm">
                  Locations
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <label
                    className={`relative inline-block h-8 w-14 cursor-pointer rounded-full transition [-webkit-tap-highlight-color:_transparent] ${!markerShow ? "bg-[#424242]" : "bg-[#87ff62]"
                      }`}
                  >
                    <input
                      className="sr-only peer"
                      id="AcceptConditions"
                      type="checkbox"
                      checked={markerShow}
                      onChange={HandleSetmarkerShow}
                    />
                    <span className="absolute inset-y-0 px-2 start-0 m-1 size-6 rounded-full bg-gray-300 ring-[6px] ring-inset ring-white transition-all peer-checked:start-8 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"></span>
                  </label>
                  <div className="pl-5 text-xl text-white max-md:text-sm">
                    Markers
                  </div>
                </div>
                <span
                  onClick={() => setDown(!down)}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={!down ? faAngleDown : faAngleUp}
                    color="white"
                  />
                </span>
              </div>
              <div
                className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${down ? "max-h-screen" : "max-h-0"
                  }`}
              >
                <div className="flex flex-col gap-2 pl-5">
                  <div className="flex items-center">
                    <label
                      className={`relative inline-block h-6 w-10 cursor-pointer rounded-full transition [-webkit-tap-highlight-color:_transparent] ${!redMarkerShow ? "bg-[#424242]" : "bg-[#ff2424]"
                        }`}
                    >
                      <input
                        className="sr-only peer"
                        id="AcceptConditions"
                        type="checkbox"
                        checked={redMarkerShow}
                        onChange={() => HandleElementShow("red")}
                      />
                      <span className="absolute inset-y-0 px-2 start-0 m-1 size-4 rounded-full bg-gray-300 ring-[6px] ring-inset ring-white transition-all peer-checked:start-4 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"></span>
                    </label>
                    <div className="pl-5 text-xl text-white max-md:text-sm">
                      Builds
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label
                      className={`relative inline-block h-6 w-10 cursor-pointer rounded-full transition [-webkit-tap-highlight-color:_transparent] ${!blueMarkerShow ? "bg-[#424242]" : "bg-[#2635ff]"
                        }`}
                    >
                      <input
                        className="sr-only peer"
                        id="AcceptConditions"
                        type="checkbox"
                        checked={blueMarkerShow}
                        onChange={() => HandleElementShow("blue")}
                      />
                      <span className="absolute inset-y-0 px-2 start-0 m-1 size-4 rounded-full bg-gray-300 ring-[6px] ring-inset ring-white transition-all peer-checked:start-4 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"></span>
                    </label>
                    <div className="pl-5 text-xl text-white max-md:text-sm">
                      Zero Build
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Follows />
          <div className="pt-10 pb-2 text-center text-white">
            Use code "Zyfe" to support me ❤️ #ad
          </div>
          <Usemode />
        </div>
      </>
    );
  };

  const MapClickHandler = () => {
    const bounds = L.latLngBounds([
      [85.56806584676865, 182.81250000000003],
      [85.11141578062661, -178.59375],
      [-84.80247372433452, -167.34375000000003],
      [-86.03110276412968, 211.64062500000003],
    ]);

    useMapEvents({
      click: (e) => {
        if (bounds.contains(e.latlng)) {
          if (isEditing) {
            setCurrentLatLng(e.latlng);
            setIsPopupVisible(true);
          }
        } else {
          console.log(
            "Click is outside the defined area. Editing mode won't be activated."
          );
        }
      },
    });

    return null;
  };

  const handleSave = async () => {
    if (videoUrl && currentLatLng) {
      const newMarker = {
        mapId: current,
        position: currentLatLng,
        videoUrl: videoUrl,
        markerType: markerType,
      };

      setLoading(true); // Start loading

      try {
        await axios.post(
          "https://fornite-backend.onrender.com/api/markers",
          newMarker
        );
        toast.success("Marker saved successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsPopupVisible(false);
        setVideoUrl("");

        const fetchMarkers = async () => {
          try {
            const encodedCurrent = encodeURIComponent(current);
            const response = await axios.get(
              `https://fornite-backend.onrender.com/api/markers/${encodedCurrent}`
            );
            const markers = response.data;
            switch (current) {
              case "/map/44/{z}/{x}/{y}.jpg":
                setMarkersLayer2(markers);
                break;
              case "/map/55/{z}/{x}/{y}.jpg":
                setMarkersLayer3(markers);
                break;
              case "/map/66/{z}/{x}/{y}.jpg":
                setMarkersLayer3(markers);
                break;
              default:
                setMarkersLayer2([]);
                setMarkersLayer3([]);
                setMarkersLayer4([]);
            }
          } catch (error) {
            console.error("Error fetching markers", error);
          }
        };

        fetchMarkers();
      } catch (error) {
        toast.error("Error saving marker", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setLoading(false); // End loading
      }
    } else if (!videoUrl) {
      toast.error("You must enter a video URL", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
    setVideoUrl("");
  };

  const handleDelete = async (markerId) => {
    setDeleting(true); // Start deleting

    try {
      await axios.delete(
        `https://fornite-backend.onrender.com/api/markers/${markerId}`
      );
      toast.success("Marker deleted successfully", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const fetchMarkers = async () => {
        try {
          const encodedCurrent = encodeURIComponent(current);
          const response = await axios.get(
            `https://fornite-backend.onrender.com/api/markers/${encodedCurrent}`
          );
          const markers = response.data;
          switch (current) {
            case "/map/44/{z}/{x}/{y}.jpg":
              setMarkersLayer2(markers);
              break;
            case "/map/55/{z}/{x}/{y}.jpg":
              setMarkersLayer3(markers);
              break;
            case "/map/66/{z}/{x}/{y}.jpg":
              setMarkersLayer4(markers);
              break;
            default:
              setMarkersLayer2([]);
              setMarkersLayer3([]);
              setMarkersLayer4([]);
          }
        } catch (error) {
          console.error("Error fetching markers", error);
        }
      };

      fetchMarkers();
    } catch (error) {
      toast.error("Error deleting marker", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="flex">
      {loading && <Loading text={"Saving..."} />}
      {deleting && <Loading text={"deleting..."} />}
      {markerLoding && <Loading text={"Page is loading..."} />}
      <SideBar />
      <ChangePassword />
      <button
        className="absolute top-4 right-4 z-[100000] bg-[#ffe262] text-black px-4 py-2 rounded-md"
        onClick={handleEditControl}
      >
        {isEditing ? (
          <FontAwesomeIcon icon={faEye} />
        ) : (
          <FontAwesomeIcon icon={faEdit} />
        )}
      </button>
      <MapContainer
        ref={mapRef}
        zoom={1}
        center={position}
        zoomControl={false}
        maxZoom={4}
        style={{ height: "100vh", width: "100%", backgroundColor: "#303136" }}
        maxBoundsViscosity={1.0}
      >
        <TileLayer
          url={current}
          attribution="&copy; Custom Map Data"
          noWrap={true}
        />
        {locationShow &&
          current === "/map/44/{z}/{x}/{y}.jpg" &&
          location2.map((marker, index) => {
            const dynamicIcon = new L.DivIcon({
              html: `<div style="color: white; width: 300px; text-align: center; position: absolute; left: -150px; text-shadow: 2px 2px 0 black, -2px 2px 0 black, 2px -2px 0 black, -2px -2px 0 black, 0 2px 0 black, 0 -2px 0 black, 2px 0 0 black, -2px 0 0 black; white; font-weight: 900;">${marker.icon}</div>`,
              className: "",
            });

            return (
              <Marker
                key={index}
                position={marker.position}
                icon={dynamicIcon}
              ></Marker>
            );
          })}
        {locationShow &&
          current === "/map/55/{z}/{x}/{y}.jpg" &&
          location3.map((marker, index) => {
            const dynamicIcon = new L.DivIcon({
              html: `<div style="color: white; width: 300px; text-align: center; position: absolute; left: -150px; text-shadow: 2px 2px 0 black, -2px 2px 0 black, 2px -2px 0 black, -2px -2px 0 black, 0 2px 0 black, 0 -2px 0 black, 2px 0 0 black, -2px 0 0 black; white; font-weight: 900;">${marker.icon}</div>`,
              className: "",
            });

            return (
              <Marker
                key={index}
                position={marker.position}
                icon={dynamicIcon}
              ></Marker>
            );
          })}
        {locationShow &&
          current === "/map/66/{z}/{x}/{y}.jpg" &&
          location4.map((marker, index) => {
            const dynamicIcon = new L.DivIcon({
              html: `<div style="color: white; width: 300px; text-align: center; position: absolute; left: -150px; text-shadow: 2px 2px 0 black, -2px 2px 0 black, 2px -2px 0 black, -2px -2px 0 black, 0 2px 0 black, 0 -2px 0 black, 2px 0 0 black, -2px 0 0 black; white; font-weight: 900;">${marker.icon}</div>`,
              className: "",
            });

            return (
              <Marker
                key={index}
                position={marker.position}
                icon={dynamicIcon}
              ></Marker>
            );
          })}
        {markerShow &&
          current === "/map/66/{z}/{x}/{y}.jpg" &&
          markersLayer4
            .filter(
              (marker) =>
                (blueMarkerShow && marker.markerType === "blue") ||
                (redMarkerShow && marker.markerType !== "blue")
            )
            .map((marker, index) => (
              <Marker
                key={index}
                position={marker.position}
                icon={
                  marker.markerType === "blue" ? customBlueIcon : customRedIcon
                }
              >
                <Popup>
                  <p
                    className={`underline cursor-pointer px-2 my-0 ${marker.markerType === "blue" ? 'text-[#1d4ed8]' : 'text-[#ff0505]'}`}
                    onClick={() => {
                      openVideoModal(marker.videoUrl);
                    }}
                  >
                    {marker.videoUrl}
                  </p>
                  {isEditing && (
                    <div
                      className="text-red-500 border hover:bg-red- rounded-md py-2 hover:bg-[#ef4444] hover:text-white p-[1px] my-0 text-center cursor-pointer transition"
                      onClick={() => handleDelete(marker._id)}
                    >
                      Delete
                    </div>
                  )}
                </Popup>
              </Marker>
            ))}
        
        {markerShow &&
          current === "/map/44/{z}/{x}/{y}.jpg" &&
          markersLayer2
            .filter(
              (marker) =>
                (blueMarkerShow && marker.markerType === "blue") ||
                (redMarkerShow && marker.markerType !== "blue")
            )
            .map((marker, index) => (
              <Marker
                key={index}
                position={marker.position}
                icon={
                  marker.markerType === "blue" ? customBlueIcon : customRedIcon
                }
              >
                <Popup>
                  <p
                    className={`underline cursor-pointer px-2 my-0 ${marker.markerType === "blue" ? 'text-[#1d4ed8]' : 'text-[#ff0505]'}`}
                    onClick={() => {
                      openVideoModal(marker.videoUrl);
                    }}
                  >
                    {marker.videoUrl}
                  </p>
                  {isEditing && (
                    <div
                      className="text-red-500 border hover:bg-red- rounded-md py-2 hover:bg-[#ef4444] hover:text-white p-[1px] my-0 text-center cursor-pointer transition"
                      onClick={() => handleDelete(marker._id)}
                    >
                      Delete
                    </div>
                  )}
                </Popup>
              </Marker>
            ))}
        {markerShow &&
          current === "/map/55/{z}/{x}/{y}.jpg" &&
          markersLayer3
            .filter(
              (marker) =>
                (blueMarkerShow && marker.markerType === "blue") ||
                (redMarkerShow && marker.markerType !== "blue")
            )
            .map((marker, index) => (
              <Marker
                key={index}
                position={marker.position}
                icon={
                  marker.markerType === "blue" ? customBlueIcon : customRedIcon
                }
              >
                <Popup>
                  <p
                    className={`underline cursor-pointer px-2 my-0 ${marker.markerType === "blue" ? 'text-[#1d4ed8]' : 'text-[#ff0505]'}`}
                    onClick={() => {
                      openVideoModal(marker.videoUrl);
                    }}
                  >
                    {marker.videoUrl}
                  </p>
                  {isEditing && (
                    <div
                      className="text-red-500 border hover:bg-red- rounded-md py-2 hover:bg-[#ef4444] hover:text-white p-[1px] my-0 text-center cursor-pointer transition"
                      onClick={() => handleDelete(marker._id)}
                    >
                      Delete
                    </div>
                  )}
                </Popup>
              </Marker>
            ))}

        {isEditing && <MapClickHandler />}
        <ZoomControl position="bottomright" />
      </MapContainer>
      <button
        className="absolute bottom-[80px] right-[10px] z-[100000] text-xl hover:scale-95 active:scale-105 transition duration-500 p-2"
        onClick={resetMap}
      >
        <FontAwesomeIcon icon={faRotate} color="#ffe262" />
      </button>

      {isPopupVisible && (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-[1000000] transition">
          <div className="transform -translate-x-1/2 -translate-y-1/2 p-6 rounded-lg fade_opacity shadow-lg top-1/2 left-1/2 absolute bg-[#1b1b1b]">
            <button
              onClick={handleCancel}
              className="absolute text-2xl font-bold text-white transition-all top-4 right-4 hover:text-gray-300"
            >
              &times;
            </button>
            <h2 className="mb-4 text-xl font-bold text-white px-28">
              Enter Video URL
            </h2>
            <input
              type="text"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              className="w-full p-2 my-3 text-gray-200 transition duration-150 ease-in-out bg-gray-700 border-0 rounded-md focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Enter video URL here"
            />
            <div className="flex items-center justify-center gap-2 py-2">
              <div className="flex items-center">
                <input
                  id="default-radio-1"
                  type="radio"
                  value={markerType === "red"}
                  name="default-radio"
                  onChange={() => setMarkerType("red")}
                  className="w-4 h-4 bg-gray-100 border-gray-300"
                />
                <label
                  htmlFor="default-radio-1"
                  className="px-1 text-sm font-medium text-white ms-2"
                >
                  Builds
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="default-radio-2"
                  type="radio"
                  value={markerType === "blue"}
                  name="default-radio"
                  onChange={() => setMarkerType("blue")}
                  className="w-4 h-4 bg-gray-100 border-gray-300"
                />
                <label
                  htmlFor="default-radio-2"
                  className="text-sm font-medium text-white ms-2 dark:text-gray-300"
                >
                  Zero Build
                </label>
              </div>
            </div>
            <div className="flex justify-around">
              <button
                className="bg-[#ef4444] w-24 text-white px-2 py-1 rounded hover:bg-[#fc6767] cursor-pointer transition"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-[#22c55e] w-24 text-white px-2 py-1 rounded hover:bg-[#70ffa5] cursor-pointer transition"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapView;
