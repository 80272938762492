import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";

const App = () => {
  return(
    <BrowserRouter>
    <div className="bg-[#333]  font-Burbank">
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
    </BrowserRouter>
  )
}

export default App;